import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import tr from './locales/tr.json';
import fr from './locales/fr.json';
import ru from './locales/ru.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          menu: {
            home: 'Home',
            boats: 'Boats',
          },
          reservation: {
            bookingMessage: 'Booking Request from Bodrum.boats',
            makeareservation: 'Make a Reservation',
            startDate: 'Start Date',
            endDate: 'End Date',
            tourDate: 'Tour Date',
            tourName: 'Tour Name',
            tourType: 'Tour Type',
            adults: 'Adults',
            children: 'Children',
            guests: 'Guests',
            bookNow: 'Book Now via Whatsapp!',
            ischarter: 'Charter?',
          },
          amenities: {
            boatDetails: 'Boat Details',
            boatType: 'Boat Type',
            lenght: 'Lenght',
            numberofCabins: 'Number of Cabins',
            engine: 'Engine',
          },
          pictures: {
            viewPhotos: 'View Photos',
          },
          topBar: {
            overview: 'Overview',
            boatDetails: 'Boat Details',
            boatPrices: 'Boat Prices',
            contactUs: 'Contact Us',
            share: 'Share',
          },
          tour: {
            prices: 'Prices',
          },
        },
      },
      tr: {
        translation: {
          reservation: {
            bookingMessage: "Bodrum.boats'tan Rezervasyon Talebi",
            makeareservation: 'Rezervasyon',
            startDate: 'Başlangıç ​​tarihi',
            endDate: 'Bitiş tarihi',
            tourDate: 'Tur Tarihi',
            tourName: 'Tur Adı',
            tourType: 'Tur Türü',
            adults: 'Yetişkinler',
            children: 'Çocuklar',
            guests: 'Misafirler',
            bookNow: 'Whatsapp ile Rezervasyon Yapın!',
          },
          amenities: {
            boatDetails: 'Tekne Detayları',
            boatType: 'Tekne Tipi',
            lenght: 'Uzunluk',
            numberofCabins: 'Kabin Sayısı',
            engine: 'Motor Gücü',
          },
          pictures: {
            viewPhotos: 'Fotoğrafları Gör',
          },
          topBar: {
            overview: 'Genel',
            boatDetails: 'Tekne Detayları',
            boatPrices: 'Tekne Fiyatları',
            contactUs: 'Bize Ulaşın',
            share: 'Paylaş',
          },
          tour: {
            prices: 'Fiyatlar',
          },
        },
      },
      fr: {
        translation: {
          pictures: {
            viewPhotos: 'View Photos',
          },
          topBar: {
            overview: 'Overview',
            boatDetails: 'Boat Details',
            boatPrices: 'Boat Prices',
            contactUs: 'Contact Us',
            share: 'Share',
          },
        },
      },
      ru: {
        translation: {
          pictures: {
            viewPhotos: 'View Photos',
          },
          topBar: {
            overview: 'Overview',
            boatDetails: 'Boat Details',
            boatPrices: 'Boat Prices',
            contactUs: 'Contact Us',
            share: 'Share',
          },
        },
      },
    },
  });

export default i18n;
