import React from "react";
import Logo from "components/UI/Logo/Logo";
import Footers from "components/Footer/Footer";
import FooterMenu from "./FooterMenu";

const Footer = () => {
  return (
    <Footers
      logo={
        <Logo
          withLink
          linkTo="/"
          src="/images/Logo.png"
          title="Bodrum Boats."
        />
      }
      tursab={
        <Logo withLink linkTo="/" src="/images/tursab.png" title="Tursab." />
      }
      menu={<FooterMenu />}
      copyright={`Copyright @ ${new Date().getFullYear()} Zar Yachting`}
    />
  );
};

export default Footer;
